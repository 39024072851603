import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Input, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import ResourceFilesFormComponent from '../../../common/filesForm'

const { Option } = Select
const { TextArea } = Input

class StyledMapObjectsForm extends ResourceFilesFormComponent {
  formRef = React.createRef()

  prepareFormValues (values) {
    return {
      name: { pl: values.name_pl, en: values.name_en },
      desc: { pl: values.desc_pl, en: values.desc_en },
      map_id: values.map_id,
      stack_type: values.stack_type
    }
  }

  prepareFormExistingValues (data) {
    return {
      name_pl: data.name.pl,
      name_en: data.name.en,
      desc_pl: data.desc.pl,
      desc_en: data.desc.en,
      map_id: data.map_id,
      stack_type: data.stack_type
    }
  }

  prepareFormDefaultValues () {
    return {
      name_pl: '',
      name_en: '',
      desc_pl: '',
      desc_en: '',
      map_id: '',
      stack_type: ''
    }
  }

  render () {
    const { language, loading } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='name_pl'
          label={messageTypes[language].name_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='name_en'
          label={messageTypes[language].name_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='desc_pl'
          label={messageTypes[language].desc_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item
          name='desc_en'
          label={messageTypes[language].desc_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item
          name='map_id'
          label={messageTypes[language].map_id}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='stack_type'
          label={messageTypes[language].stack_type}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...this.formItemLayout}
        >
          <Select>
            {
              this.props.overlordStackTypes.length
                ? this.props.overlordStackTypes.map((stackType, n) =>
                  <Option key={n} value={stackType.name}>{stackType.name}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        {this.fileFieldsRender('map_objects')}

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledMapObjectsForm
