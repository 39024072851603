import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Input, Button, Divider
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select
const { TextArea } = Input

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledVpnDescriptionsForm extends React.Component {
  formRef = React.createRef()
  state = {
    stackNetworks: []
  }

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = {}
    obj.name = {}
    obj.name.pl = values.name_pl
    obj.name.en = values.name_en
    obj.desc = {}
    obj.desc.pl = values.desc_pl
    obj.desc.en = values.desc_en
    obj.network_name = values.network_name
    obj.stack_type = values.stack_type

    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  setDefaultValues = () => {
    const { data, isNewRecord } = this.props

    if (data && Object.keys(data).length > 0 && !isNewRecord) {
      const stackType = this.props.overlordStackTypes.find(x => x.path === data.stack_type)
      const stackNetworks = stackType?.networks || []

      this.setState({ stackNetworks }, () => {
        const fieldsVal = {
          name_pl: data.name.pl,
          name_en: data.name.en,
          desc_pl: data.desc.pl,
          desc_en: data.desc.en,
          stack_type: data.stack_type,
          network_name: data.network_name
        }
        this.formRef.current.setFieldsValue(fieldsVal)
      })
    } else {
      this.setState({
      }, () => {
        this.formRef.current.setFieldsValue({
          name_pl: '',
          name_en: '',
          desc_pl: '',
          desc_en: '',
          network_name: '',
          stack_type: ''
        })
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  handleStackTypeChange = (stackTypeUsed) => {
    const stackType = this.props.overlordStackTypes.find(x => x.path === stackTypeUsed)
    const stackNetworks = stackType?.networks || []

    this.setState({ stackNetworks }, () => {
      this.formRef.current.setFieldsValue({
        network_name: ''
      })
    })
  }

  render () {
    const { language, loading } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='name_pl'
          label={messageTypes[language].name_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='name_en'
          label={messageTypes[language].name_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='desc_pl'
          label={messageTypes[language].desc_pl}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item
          name='desc_en'
          label={messageTypes[language].desc_en}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <TextArea rows={6} />
        </Form.Item>

        <Form.Item
          name='stack_type'
          label={messageTypes[language].stack_type}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select onChange={this.handleStackTypeChange}>
            {
              this.props.overlordStackTypes.length
                ? this.props.overlordStackTypes.map((stackType, n) =>
                  <Option key={n} value={stackType.name}>{stackType.name}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='network_name'
          label={messageTypes[language].network_name}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Select>
            {
              this.state.stackNetworks.length
                ? this.state.stackNetworks.map((network, n) =>
                  <Option key={n} value={network}>{network}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledVpnDescriptionsForm
