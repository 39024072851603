import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledScenariosTableSettings from '../../../../styledComponents/sections/settings/scenarios/scenariosTable'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import ScenariosModalSettings from './scenariosModal'
import {
  openNotificationPopup,
  getCookie,
  deleteElementWithIdFromArr,
  confirm
} from '../../../../actions/helpers'

import {
  machinesGetVmware,
  scenariosGetBuilder,
  scenariosCreateBuilder,
  scenariosUpdateBuilder,
  scenariosDeleteBuilder
} from '../../../../actions/'
import { CheckOutlined } from '@ant-design/icons'

import { messageTypes } from '../../../../actions/messages'

class ScenariosSettings extends Component {
  state = {
    machines: [],
    scenarios: [],
    data: [],
    dataId: null,
    loading: false,
    isNewRecord: false
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  setDataId = (dataId) => { this.setState({ dataId }) }
  showAddRecordModal = (isNewRecord) => { this.setState({ isNewRecord }) }

  getAllData = () => {
    const { language } = this.props
    scenariosGetBuilder(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
    machinesGetVmware(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            machines: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_scenario_question', language, () => {
      scenariosDeleteBuilder(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    scenariosUpdateBuilder(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({
          loading: false,
          dataId: null
        })
        if (json.status === 'ok') {
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.getAllData()
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    scenariosCreateBuilder(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        this.setState({ isNewRecord: false })
        this.getAllData()
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.name = val.name[lang]
      obj.desc = val.desc && val.desc[lang]
      obj.desc_long = val.desc_long && val.desc_long[lang]
      obj.difficulty = val.difficulty
      obj.active_icon = val.active ? <CheckOutlined /> : ''
      obj.tags = val.tags
      obj.files = val.files
      obj.active = val.active
      obj.machines = val.machines
      obj.order = val.order
      obj.s_type = messageTypes[lang]['scenario_type_' + val.s_type]
      obj.stack_types = val.stack_types.join(', ')
      retArr.push(obj)
    })
    return retArr
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { data, dataId, isNewRecord, machines } = this.state
    const { language } = this.props
    return (
      <div>
        <AddRecordButton
          language={language}
          showAddRecordModal={this.showAddRecordModal}
          title={messageTypes[language].new_scenario}
        />
        <StyledScenariosTableSettings
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          showAddRecordModal={this.showAddRecordModal}
        />
        <ScenariosModalSettings
          language={language}
          machines={machines}
          data={data}
          dataId={dataId}
          isNewRecord={isNewRecord}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addRecord={this.addRecord}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const ScenariosSettingsContainer = connect(
  mapStateToProps
)(ScenariosSettings)

export default ScenariosSettingsContainer
