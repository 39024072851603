import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledStacksTableSettings from '../../../../styledComponents/sections/settings/stacks/stacksTable'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import RefreshButton from '../../../../styledComponents/common/refreshButton'
import StacksModalSettings from './stackModal'
import {
  openNotificationPopup,
  getCookie,
  deleteElementWithIdFromArr,
  confirm
} from '../../../../actions/helpers'
import { CheckOutlined } from '@ant-design/icons'
import settings from '../../../../config/'
import {
  stacksGet,
  stacksOverlordGet,
  stackTypesOverlordGet,
  stackCreate,
  stackUpdate,
  stackDelete,
  stackAction,
  adminUnfreezeStackRanking,
  adminFreezeStackRanking,
  adminClearStackCtfFlags
} from '../../../../actions/'

import { messageTypes } from '../../../../actions/messages'

class StacksSettings extends Component {
  state = {
    data: [],
    dataId: null,
    loading: false,
    isNewRecord: false,
    overlordStacks: [],
    overlordStacksAvailable: [],
    overlordStackTypes: []
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  setDataId = (dataId) => { this.setState({ dataId }) }
  showAddRecordModal = (isNewRecord) => { this.setState({ isNewRecord }) }

  getAllData = () => {
    const { language } = this.props
    this.setState({
      loading: true
    })
    stacksGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })

          if (settings.overlord_enabled) {
            this.getStacksFromOverlord()
            this.getStackTypesFromOverlord()
          }
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }

        if (!settings.overlord_enabled) {
          this.setState({
            loading: false
          })
        }
      }
    })
  }

  getStacksFromOverlord = () => {
    const { language } = this.props
    stacksOverlordGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        const usedStackNrs = this.state.data.map(x => x.nr)

        if (json.status === 'ok') {
          this.setState({
            overlordStacks: json.response,
            overlordStacksAvailable: json.response && json.response.filter(x => !usedStackNrs.includes(x.stack_nr))
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }

        this.setState({
          loading: false
        })
      }
    })
  }

  getStackTypesFromOverlord = () => {
    const { language } = this.props
    stackTypesOverlordGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            overlordStackTypes: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }

        this.setState({
          loading: false
        })
      }
    })
  }

  executeStackAction = (id, action) => {
    const { language } = this.props
    confirm('stack_' + action + '_question', language, () => {
      stackAction(id, action, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.getAllData()
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_stack_question', language, () => {
      stackDelete(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
            this.getAllData()
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })

    stackUpdate(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({
          loading: false
        })

        if (json.status === 'ok') {
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.getAllData()
          this.setState({
            dataId: null
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  unfreezeRanking = (id) => {
    const { language } = this.props

    confirm('unfreeze_ranking_stack_question', language, () => {
      adminUnfreezeStackRanking(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
            this.getAllData()
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  freezeRanking = (id) => {
    const { language } = this.props

    confirm('freeze_ranking_stack_question', language, () => {
      adminFreezeStackRanking(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
            this.getAllData()
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    stackCreate(data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({ loading: false })

        if (json.status === 'ok') {
          openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
          this.setState({ isNewRecord: false })
          this.getAllData()
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  clearStackCtfFlags = (id) => {
    const { language } = this.props

    confirm('clear_stack_ctf_flags_question', language, () => {
      adminClearStackCtfFlags(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.name = val.name
      obj.type = val.type
      obj.demo = val.demo
      obj.nr = val.nr
      obj.is_ctf_icon = val.is_ctf ? <CheckOutlined /> : ''
      obj.is_ctf = val.is_ctf
      obj.ctf_type = val.ctf_type
      obj.users = val.users
      obj.frozen_scoreboard = val.frozen_scoreboard

      const overlordStack = this.state.overlordStacks.find(x => x.stack_nr === val.nr)
      obj.status = overlordStack && overlordStack.status

      retArr.push(obj)
    })
    return retArr
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { data, dataId, isNewRecord, overlordStacksAvailable, loading } = this.state
    const { language } = this.props

    return (
      <div>
        <RefreshButton
          language={language}
          refreshAction={this.getAllData}
          loading={loading}
        />
        <AddRecordButton
          language={language}
          showAddRecordModal={this.showAddRecordModal}
          title={messageTypes[language].new_stack}
        />
        <StyledStacksTableSettings
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          executeStackAction={this.executeStackAction}
          showAddRecordModal={this.showAddRecordModal}
          unfreezeRanking={this.unfreezeRanking}
          freezeRanking={this.freezeRanking}
          clearStackCtfFlags={this.clearStackCtfFlags}
        />
        <StacksModalSettings
          language={language}
          data={data}
          dataId={dataId}
          isNewRecord={isNewRecord}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addRecord={this.addRecord}
          overlordStacks={overlordStacksAvailable}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const StacksSettingsContainer = connect(
  mapStateToProps
)(StacksSettings)

export default StacksSettingsContainer
