import React from 'react'
import { Modal } from 'antd'
import { messageTypes } from '../../../../actions/messages'
import { takeElementWithIdFromArr, getCookie, openNotificationPopup } from '../../../../actions/helpers'
import StyledStackForm from '../../../../styledComponents/sections/settings/stacks/stackForm'
import { stackTypesOverlordGet } from '../../../../actions/'

class StacksModalSettings extends React.Component {
  state = {
    singleData: {},
    dataTitle: null,
    overlordStackTypes: []
  }

  handleCancel = () => {
    const { setDataId, showAddRecordModal } = this.props
    showAddRecordModal(false)
    setDataId(null)
    this.setDataTitle('')
  }

  setDataTitle = (dataTitle) => {
    this.setState({ dataTitle })
  }

  getStackTypesFromOverlord = () => {
    const { language } = this.props
    stackTypesOverlordGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            overlordStackTypes: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }

        this.setState({
          loading: false
        })
      }
    })
  }

  componentDidMount () {
    this._isMounted = true
    this.getStackTypesFromOverlord()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataId, data } = this.props
    if (prevProps.dataId !== dataId) {
      const singleData = takeElementWithIdFromArr(dataId, data)
      this.setState({ singleData, dataTitle: singleData && singleData.name })
    }
  }

  render () {
    const {
      language, dataId, isNewRecord, loading,
      updateRecord, addRecord, overlordStacks
    } = this.props
    const { singleData, dataTitle } = this.state

    return (
      <div>
        <Modal
          maskClosable={false}
          width={1000}
          visible={!!(dataId || isNewRecord)}
          title={
            (isNewRecord && ((dataTitle && messageTypes[language].new_stack + ': ' + dataTitle) || messageTypes[language].new_stack)) ||
            (
              singleData &&
              messageTypes[language].stack) + ': ' + dataTitle
          }
          onCancel={this.handleCancel}
          footer={null}
        >
          <StyledStackForm
            isNewRecord={isNewRecord}
            data={singleData || {}}
            language={language}
            onCancel={this.handleCancel}
            updateRecord={updateRecord}
            addRecord={addRecord}
            loading={loading}
            stacks={overlordStacks}
            setDataTitle={this.setDataTitle}
            overlordStackTypes={this.state.overlordStackTypes}
          />
        </Modal>
      </div>
    )
  }
}

export default StacksModalSettings
