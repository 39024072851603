import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Input, Button, Divider, Checkbox, Tag,
  InputNumber, Switch, Tooltip, DatePicker
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import settings from '../../../../config/'
import moment from 'moment'
import dateformat from 'dateformat'

import 'moment/locale/pl'
import datePickerPL from 'antd/es/date-picker/locale/pl_PL'
import datePickerEN from 'antd/es/date-picker/locale/en_US'

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledStackForm extends React.Component {
  formRef = React.createRef()
  state = {
    useExistingStack: true,
    useOverlord: settings.overlord_enabled,
    isCtf: false,
    ctfTimeLimit: false,
    ctfFreezeScoreboard: false,
    ctfType: undefined
  }

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord, setDataTitle } = this.props
    const obj = {}
    obj.use_overlord = this.state.useOverlord
    obj.name = values.name
    obj.demo = values.demo
    obj.ctf_type = values.ctf_type
    obj.disable_steerings = values.disable_steerings
    obj.ctf_time_limit = values.ctf_time_limit
    obj.ctf_start_date = values.ctf_start_date && dateformat(values.ctf_start_date, 'yyyy-mm-dd HH:MM')
    obj.ctf_end_date = values.ctf_end_date && dateformat(values.ctf_end_date, 'yyyy-mm-dd HH:MM')
    obj.freeze_scoreboard = values.freeze_scoreboard
    obj.freeze_scoreboard_date = values.freeze_scoreboard_date && dateformat(values.freeze_scoreboard_date, 'yyyy-mm-dd HH:MM')

    if (!this.state.useOverlord) {
      obj.type = values.type
      obj.nr = values.nr
      obj.is_ctf = values.is_ctf
      obj.vmware_user = values.vmware_user
      obj.vmware_password = values.vmware_password
      obj.vmware_admin_user = values.vmware_admin_user
      obj.vmware_admin_password = values.vmware_admin_password
    }

    if (isNewRecord && settings.overlord_enabled && this.state.useOverlord) {
      obj.create_new_stack = values.create_new_stack
      obj.type = values.type

      if (!values.create_new_stack) {
        obj.stack = values.stack
      }
    }

    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }

    setDataTitle('')
  }

  setDefaultValues = () => {
    const { data, isNewRecord } = this.props

    if (data && Object.keys(data).length > 0 && !isNewRecord) {
      this.setState({
        isCtf: data.is_ctf,
        ctfTimeLimit: data.ctf_time_limit,
        ctfFreezeScoreboard: data.freeze_scoreboard,
        ctfType: data.ctf_type
      }, () => {
        const fieldsVal = {
          name: data.name,
          demo: data.demo,
          type: data.type,
          is_ctf: data.is_ctf,
          ctf_type: data.ctf_type,
          nr: data.nr,
          vmware_user: data.vmware_user,
          vmware_admin_user: data.vmware_admin_user,
          disable_steerings: data.disable_steerings,
          ctf_time_limit: data.ctf_time_limit,
          ctf_start_date: data.ctf_start_date && moment(data.ctf_start_date),
          ctf_end_date: data.ctf_end_date && moment(data.ctf_end_date),
          freeze_scoreboard: data.freeze_scoreboard,
          freeze_scoreboard_date: data.freeze_scoreboard_date && moment(data.freeze_scoreboard_date)
        }
        this.formRef.current.setFieldsValue(fieldsVal)
      })
    } else {
      this.setState({
        useExistingStack: true,
        useOverlord: settings.overlord_enabled,
        isCtf: false,
        ctfTimeLimit: false,
        ctfFreezeScoreboard: false,
        ctfType: undefined
      }, () => {
        this.formRef.current.setFieldsValue({
          name: undefined,
          demo: undefined,
          nr: undefined,
          is_ctf: false,
          ctf_type: undefined,
          stack: undefined,
          vmware_user: '',
          vmware_password: '',
          vmware_admin_user: '',
          vmware_admin_password: '',
          create_new_stack: false,
          disable_steerings: false,
          ctf_time_limit: false,
          ctf_start_date: undefined,
          ctf_end_date: undefined,
          type: '',
          freeze_scoreboard: false,
          freeze_scoreboard_date: undefined
        })
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  handleCreateNewChange = (value) => {
    this.setState({ useExistingStack: !value.target.checked })
  }

  handleStackChange = (stackId) => {
    this.setState({ isCtf: !!this.props.stacks.find(x => x.id === stackId)?.is_ctf })
  }

  handleSwitch = () => {
    this.setState({ useOverlord: !this.state.useOverlord })
  }

  handleIsCtfChange = (value) => {
    this.setState({ isCtf: value.target.checked })
  }

  handleCtfTypeChange = (value) => {
    this.setState({ ctfType: value })
  }

  handleNameChange = (value) => {
    this.props.setDataTitle(value.target.value)
  }

  render () {
    const { language, loading, stacks, isNewRecord, data } = this.props
    const { useExistingStack, useOverlord, isCtf, ctfTimeLimit, ctfFreezeScoreboard, ctfType } = this.state

    const locale = (language === 'en' && datePickerEN) || datePickerPL

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='use_overlord'
          label={messageTypes[language].use_overlord}
          {...formItemLayout}
        >
          <Tooltip placement='top' title={!settings.overlord_enabled && messageTypes[language].overlord_connection_unavailable}>
            <Switch
              checked={this.state.useOverlord}
              disabled={!settings.overlord_enabled}
              onChange={() => this.handleSwitch()}
            />
          </Tooltip>
        </Form.Item>

        <Form.Item
          name='name'
          label={messageTypes[language].stack_name}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input onChange={this.handleNameChange} />
        </Form.Item>

        {isNewRecord && useOverlord && (
          <>
            <Form.Item
              name='create_new_stack'
              label={messageTypes[language].create_new_stack}
              valuePropName='checked'
              {...formItemLayout}
            >
              <Checkbox onChange={this.handleCreateNewChange} />
            </Form.Item>

            {(useExistingStack && (
              <Form.Item
                name='stack'
                label={messageTypes[language].select_existing_stack}
                rules={[{ required: true, message: messageTypes[language].field_required }]}
                {...formItemLayout}
              >
                <Select allowClear onChange={this.handleStackChange}>
                  {
                    stacks && stacks.length
                      ? stacks.map((stack, n) =>
                        <Option key={n} value={stack.id}>{stack.stack_nr} ({stack.stack_type}) <Tag>{stack.status}</Tag> {stack.description}</Option>
                      )
                      : ''
                  }
                </Select>
              </Form.Item>
            ))}
          </>
        )}

        {(!useOverlord || !useExistingStack) && (
          <Form.Item
            name='type'
            label={messageTypes[language].stack_type}
            rules={[{ required: true, message: messageTypes[language].field_required }]}
            {...formItemLayout}
          >
            <Select>
              {
                this.props.overlordStackTypes.length
                  ? this.props.overlordStackTypes.map((stackType, n) =>
                    <Option key={n} value={stackType.name}>{stackType.name}</Option>
                  )
                  : ''
              }
            </Select>
          </Form.Item>)}

        {!useOverlord && (
          <>
            <Form.Item
              name='nr'
              label={messageTypes[language].nr}
              rules={[{ required: true, message: messageTypes[language].field_required }]}
              {...formItemLayout}
            >
              <InputNumber min={0} max={255} />
            </Form.Item>

            <Form.Item
              name='is_ctf'
              label={messageTypes[language].is_ctf}
              valuePropName='checked'
              {...formItemLayout}
            >
              <Checkbox onChange={this.handleIsCtfChange} defaultChecked={data.is_ctf || ''} />
            </Form.Item>

            <Form.Item
              name='vmware_user'
              label={messageTypes[language].vmware_user}
              rules={[{ required: true, message: messageTypes[language].field_required }]}
              {...formItemLayout}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='vmware_password'
              label={messageTypes[language].vmware_password}
              rules={[{ required: isNewRecord || !data.vmware_password, message: messageTypes[language].field_required }]}
              {...formItemLayout}
            >
              <Input.Password placeholder={data.vmware_password && '***********'} />
            </Form.Item>

            <Form.Item
              name='vmware_admin_user'
              label={messageTypes[language].vmware_admin_user}
              {...formItemLayout}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='vmware_admin_password'
              label={messageTypes[language].vmware_admin_password}
              {...formItemLayout}
            >
              <Input.Password placeholder={data.vmware_admin_password && '***********'} />
            </Form.Item>

          </>)}

        {isCtf &&
          <Form.Item
            name='ctf_type'
            label={messageTypes[language].ctf_type}
            rules={[{ required: true, message: messageTypes[language].field_required }]}
            {...formItemLayout}
          >
            <Select allowClear onChange={this.handleCtfTypeChange}>
              {
                ['netwars', 'jeopardy'].map((ctfType, n) =>
                  <Option key={n} value={ctfType}>{ctfType}</Option>
                )
              }
            </Select>
          </Form.Item>}

        <Form.Item
          name='demo'
          label={messageTypes[language].demo}
          {...formItemLayout}
        >
          <Select allowClear>
            {
              settings.demo_types && settings.demo_types.length
                ? settings.demo_types.map((demoType, n) =>
                  <Option key={n} value={demoType}>{demoType}</Option>
                )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item
          name='disable_steerings'
          label={messageTypes[language].stack_disable_steerings}
          valuePropName='checked'
          {...formItemLayout}
        >
          <Checkbox defaultChecked={data.disable_steerings || ''} />
        </Form.Item>

        {isCtf &&
          <Form.Item
            name='ctf_time_limit'
            label={messageTypes[language].ctf_time_limit}
            {...formItemLayout}
          >
            <Switch checked={ctfTimeLimit} onChange={(x) => this.setState({ ctfTimeLimit: x })} />
          </Form.Item>}

        {isCtf && ctfTimeLimit &&
          <Form.Item
            name='ctf_start_date'
            label={messageTypes[language].ctf_start_date}
            rules={[{ required: true, message: messageTypes[language].field_required }]}
            {...formItemLayout}
          >
            <DatePicker showTime format='YYYY-MM-DD HH:mm' minuteStep={5} locale={locale} onChange={() => this.formRef.current?.validateFields(['ctf_end_date'])} />
          </Form.Item>}
        {isCtf && ctfTimeLimit &&
          <Form.Item
            name='ctf_end_date'
            label={messageTypes[language].ctf_end_date}
            rules={[
              { required: true, message: messageTypes[language].field_required },
              {
                message: messageTypes[language].ctf_end_date_cannot_be_lesser_or_equal_than_start_date,
                validator: (_, endDate) => {
                  const startDate = this.formRef.current.getFieldValue('ctf_start_date')

                  if (endDate && startDate && (endDate < startDate || endDate.isSame(startDate))) {
                    return Promise.reject(new Error('end date cannot be lesser or equal than start date'))
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
            {...formItemLayout}
          >
            <DatePicker showTime format='YYYY-MM-DD HH:mm' minuteStep={5} locale={locale} />
          </Form.Item>}

        {isCtf && ctfType === 'jeopardy' &&
          <Form.Item
            name='freeze_scoreboard'
            label={messageTypes[language].freeze_scoreboard}
            {...formItemLayout}
          >
            <Switch checked={ctfFreezeScoreboard} onChange={(x) => this.setState({ ctfFreezeScoreboard: x })} />
          </Form.Item>}

        {isCtf && ctfFreezeScoreboard &&
          <Form.Item
            name='freeze_scoreboard_date'
            label={messageTypes[language].freeze_scoreboard_date}
            rules={[{ required: true, message: messageTypes[language].field_required }]}
            {...formItemLayout}
          >
            <DatePicker showTime format='YYYY-MM-DD HH:mm' minuteStep={5} locale={locale} />
          </Form.Item>}

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledStackForm
