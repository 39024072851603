import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledMapsTable from '../../../../styledComponents/sections/settings/maps/mapsTable'
import settings from '../../../../config/'
import { stackTypesOverlordGet } from '../../../../actions/'
import { getCookie, openNotificationPopup } from '../../../../actions/helpers'
import { messageTypes } from '../../../../actions/messages'

class Maps extends Component {
  state = {
    data: settings.stack_types
  }

  getStackTypesFromOverlord = () => {
    const { language } = this.props
    stackTypesOverlordGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response.map(x => x.name)
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }

        this.setState({
          loading: false
        })
      }
    })
  }

  componentDidMount () {
    this._isMounted = true
    this.getStackTypesFromOverlord()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj.type = val

      retArr.push(obj)
    })
    return retArr
  }

  render () {
    const { data } = this.state
    const { language } = this.props

    return (
      <StyledMapsTable
        language={language}
        data={this.returnWithKey(data, language)}
      />
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const MapsContainer = connect(
  mapStateToProps
)(Maps)

export default MapsContainer
