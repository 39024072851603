import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledMachinesTableSettings from '../../../../styledComponents/sections/settings/machines/machinesTable'
import MachinesModalSettings from './machinesModal'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import { openNotificationPopup, getCookie, deleteElementWithIdFromArr, confirm } from '../../../../actions/helpers'
import { messageTypes } from '../../../../actions/messages'
import {
  machinesGetBuilder,
  machinesCreateBuilder,
  machinesUpdateBuilder,
  machinesDeleteBuilder
} from '../../../../actions/'

class MachinesSettings extends Component {
  state = {
    scenarios: [],
    data: [],
    machines: [],
    dataId: null,
    loading: false,
    isNewRecord: false
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  setDataId = (dataId) => { this.setState({ dataId }) }
  showAddRecordModal = (isNewRecord) => { this.setState({ isNewRecord }) }

  getAllData = () => {
    const { language } = this.props
    machinesGetBuilder(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props

    confirm('delete_machine_question', language, () => {
      machinesDeleteBuilder(id, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
            openNotificationPopup(
              messageTypes[language].success,
              json.response[language],
              'smile'
            )
          } else if (json.status === 'err') {
            openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
          }
        }
      })
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    machinesUpdateBuilder(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({
          loading: false
        })
        if (json.status === 'ok') {
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.getAllData()
          this.setState({
            dataId: null
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    machinesCreateBuilder(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        this.setState({ isNewRecord: false })
        this.getAllData()
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.desc = val.desc[lang]
      obj.name = val.machine_name
      obj.order = val.order
      obj.stack_types = val.stack_types?.join(', ') || ''
      obj.active = val.active || ''
      retArr.push(obj)
    })
    return retArr
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { data, dataId, isNewRecord, machines } = this.state
    const { language } = this.props

    return (
      <div>
        <AddRecordButton
          language={language}
          showAddRecordModal={this.showAddRecordModal}
          title={messageTypes[language].new_machine}
        />
        <StyledMachinesTableSettings
          machines={machines}
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          showAddRecordModal={this.showAddRecordModal}
        />
        <MachinesModalSettings
          language={language}
          machines={machines}
          data={data}
          dataId={dataId}
          isNewRecord={isNewRecord}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addRecord={this.addRecord}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const MachinesSettingsContainer = connect(
  mapStateToProps
)(MachinesSettings)

export default MachinesSettingsContainer
